<template>
    <div class="pc guest">
        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!-- Fin spiner -->
        <div v-else-if="!isPreLoad && booking.status == '0'" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <h1>{{ translator.reservacaducada }}</h1>
            </div>
        </div>
        <div v-else>

            <header class="header-pc">
                <NavbarGuest :translator="translator" :user="user" :booking="booking" />
            </header>

            <!-- Imatge 0 de la propietat -->
            <section v-if="booking._image_format" class="foto-bienvenida" :style="booking._image_format"></section>

            <div class="huesped container py-3 p-xl-4" v-if="translator.nits && booking.reservation">
                <h2 v-cloak>{{ translator.latevareserva }}</h2>


                <!-- Dades de la reserva -->
                <div class="contenedor-reserva shadow-sm d-flex">

                    <a v-if="isMarcaBlanca" :href="`https://${user.user_app}/info?id=${paramID}`"
                        class="d-flex align-items-center mr-auto">
                        <div class="icono">
                            <i class="fal fa-house-user fa-2x"></i>
                        </div>
                        <div class="info pl-3">
                            <div class="periodo-estancia" v-cloak>
                                {{ booking.lodging_name }} <!--- ID {{ booking.reservation }}-->
                            </div>
                            <div class="nombre-alojamiento" v-cloak>
                                {{ booking.arrival_date | transformDate }} {{ booking.arrival_time }}<span
                                    v-if="booking.arrival_time != ''">h</span>
                                -
                                {{ booking.departure_date | transformDate }} {{ booking.departure_time }}<span
                                    v-if="booking.departure_time != ''">h </span>

                                <div style="display: block;">
                                    {{ booking.nights }}
                                    <span v-if="parseInt(booking.nights) == 1">{{ translator.nit | toLower }}</span>
                                    <span v-else>{{ translator.nits | toLower }}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                    <router-link v-else :to="{ name: 'GuestInfo', query: { id: paramID } }"
                        class="d-flex align-items-center mr-auto">
                        <div class="icono">
                            <i class="fal fa-house-user fa-2x"></i>
                        </div>
                        <div class="info pl-3">
                            <div class="periodo-estancia" v-cloak>
                                {{ booking.lodging_name }} <!--- ID {{ booking.reservation }}-->
                            </div>
                            <div class="nombre-alojamiento" v-cloak>
                                {{ booking.arrival_date | transformDate }} {{ booking.arrival_time }}<span
                                    v-if="booking.arrival_time != ''">h</span>
                                -
                                {{ booking.departure_date | transformDate }} {{ booking.departure_time }}<span
                                    v-if="booking.departure_time != ''">h </span>

                                <div style="display: block;">
                                    {{ booking.nights }}
                                    <span v-if="parseInt(booking.nights) == 1">{{ translator.nit | toLower }}</span>
                                    <span v-else>{{ translator.nits | toLower }}</span>
                                </div>
                            </div>
                        </div>
                    </router-link>

                    <div class=" p-1">
                        <a target="_blank"
                            :href="`https://www.google.com/maps/search/?api=1&query=${bookingPayments.lodging_latitude},${bookingPayments.lodging_longitude}`"
                            class="p-2 map">
                            <div class="icono">
                                <i class="fal fa-map-marker-alt fa-2x"></i>
                            </div>
                        </a>
                    </div>
                </div>


                <!-- Dades personals -->
                <a v-if="isMarcaBlanca" :href="`https://${user.user_app}/details?id=${paramID}`"
                    class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-file-check fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia" v-cloak>
                            {{ translator.dadespersonals }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak>
                            {{ booking.customer_first_name }} {{ booking.customer_last_name }}
                            <div style="display: block;">
                                {{ booking.customer_email }}
                            </div>
                        </div>
                    </div>
                    <div class="ml-auto" v-if="
                        booking.customer_address &&
                        booking.customer_city &&
                        booking.customer_country &&
                        booking.customer_email &&
                        booking.customer_first_name &&
                        booking.customer_last_name &&
                        booking.customer_phone &&
                        booking.customer_tin &&
                        booking.customer_zip &&
                        booking.customer_nationality
                    ">
                        <i class="far fa-check-circle fa-lg icono-completo"></i>
                    </div>
                    <div class="ml-auto" v-else-if="
                        booking.customer_last_name === '' &&
                        booking.customer_phone === '' &&
                        booking.customer_address === '' &&
                        booking.customer_city === '' &&
                        booking.customer_zip === '' &&
                        booking.customer_country === '' &&
                        booking.customer_tin === '' &&
                        booking.customer_nationality === ''
                    ">
                    </div>
                    <div class="ml-auto" v-else-if="
                        booking.customer_first_name == '' ||
                        booking.customer_last_name == '' ||
                        booking.customer_email == '' ||
                        booking.customer_phone == '' ||
                        booking.customer_address == '' ||
                        booking.customer_city == '' ||
                        booking.customer_zip == '' ||
                        booking.customer_country == '' ||
                        booking.customer_tin == '' ||
                        booking.customer_nationality == ''
                    ">
                        <i class="far fa-exclamation-triangle fa-lg icono-completo"
                            style="color:var(--colores-amarillo-2);"></i>
                        <!-- data-toggle="tooltip" data-placement="top" title="Faltan datos" -->
                    </div>
                </a>
                <router-link v-else :to="{ name: 'GuestDetails', query: { id: paramID } }"
                    class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-file-check fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia" v-cloak>
                            {{ translator.dadespersonals }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak>
                            {{ booking.customer_first_name }} {{ booking.customer_last_name }}
                            <div style="display: block;">
                                {{ booking.customer_email }}
                            </div>
                        </div>
                    </div>
                    <div class="ml-auto" v-if="
                        booking.customer_address &&
                        booking.customer_city &&
                        booking.customer_country &&
                        booking.customer_email &&
                        booking.customer_first_name &&
                        booking.customer_last_name &&
                        booking.customer_phone &&
                        booking.customer_tin &&
                        booking.customer_zip &&
                        booking.customer_nationality
                    ">
                        <i class="far fa-check-circle fa-lg icono-completo"></i>
                    </div>
                    <div class="ml-auto" v-else-if="
                        booking.customer_last_name === '' &&
                        booking.customer_phone === '' &&
                        booking.customer_address === '' &&
                        booking.customer_city === '' &&
                        booking.customer_zip === '' &&
                        booking.customer_country === '' &&
                        booking.customer_tin === '' &&
                        booking.customer_nationality === ''
                    ">
                    </div>
                    <div class="ml-auto" v-else-if="
                        booking.customer_first_name == '' ||
                        booking.customer_last_name == '' ||
                        booking.customer_email == '' ||
                        booking.customer_phone == '' ||
                        booking.customer_address == '' ||
                        booking.customer_city == '' ||
                        booking.customer_zip == '' ||
                        booking.customer_country == '' ||
                        booking.customer_tin == '' ||
                        booking.customer_nationality == ''
                    ">
                        <i class="far fa-exclamation-triangle fa-lg icono-completo"
                            style="color:var(--colores-amarillo-2);"></i>
                        <!-- data-toggle="tooltip" data-placement="top" title="Faltan datos" -->
                    </div>
                </router-link>


                <!-- Hostes registrats -->
                <a v-if="isMarcaBlanca" :href="`https://app.icnea.net/guests?id=${paramID}`"
                    class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-users fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia" v-cloak>
                            {{ translator.hostes }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak>
                            {{ booking.registered_guests }}/{{ booking.sumAdultsChildren }}
                            {{ translator.registrats | toLower }}
                        </div>
                    </div>

                    <div v-if="(
                        (booking.mandatory_guests_at_check_in === 'true' && booking._registered_adults_only_completed) ||
                        booking.mandatory_guests_at_check_in === 'false'
                    ) &&
                        (
                            (booking.mandatory_signatures_contract === 'true' && controlAdultWithSign) ||
                            booking.mandatory_signatures_contract === 'false'
                        ) &&
                        (
                            (booking.mandatory_guests_contract === 'true' && isFilledFields && booking._registered_adults_only_completed) ||
                            booking.mandatory_guests_contract === 'false'
                        ) &&
                        (
                            booking.mandatory_guests_at_check_in == 'false' ||
                            (
                                (booking.mandatory_guests_at_check_in === 'true') &&
                                ((booking.visible_selfie === 'true' && is_allSelfiGuests) || booking.visible_selfie === 'false') &&
                                ((booking.visible_passport === 'true' && is_allPhotoDocuments) || booking.visible_passport === 'false') &&
                                ((booking.visible_document === 'true' && is_allAnversoDocument) || booking.visible_document === 'false')
                            )
                        )" class="ml-auto">
                        <i class="far fa-check-circle fa-lg icono-completo"></i>
                    </div>

                    <!-- <div class="ml-auto" v-else-if="booking.registered_guests == 0">
                    </div> -->
                    <div class="ml-auto" v-else>
                        <i class="far fa-exclamation-triangle fa-lg icono-completo"
                            style="color:var(--colores-amarillo-2);"></i>
                    </div>
                </a>
                <router-link v-else :to="{ name: 'GuestGuests', query: { id: paramID } }"
                    class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-users fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia" v-cloak>
                            {{ translator.hostes }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak>
                            {{ booking.registered_guests }}/{{ booking.sumAdultsChildren }}
                            {{ translator.registrats | toLower }}
                        </div>
                    </div>

                    <div v-if="(
                        (booking.mandatory_guests_at_check_in === 'true' && booking._registered_adults_only_completed) ||
                        booking.mandatory_guests_at_check_in === 'false'
                    ) &&
                        (
                            (booking.mandatory_signatures_contract === 'true' && controlAdultWithSign) ||
                            booking.mandatory_signatures_contract === 'false'
                        ) &&
                        (
                            (booking.mandatory_guests_contract === 'true' && isFilledFields && booking._registered_adults_only_completed) ||
                            booking.mandatory_guests_contract === 'false'
                        ) &&
                        (
                            booking.mandatory_guests_at_check_in == 'false' ||
                            (
                                (booking.mandatory_guests_at_check_in === 'true') &&
                                ((booking.visible_selfie === 'true' && is_allSelfiGuests) || booking.visible_selfie === 'false') &&
                                ((booking.visible_passport === 'true' && is_allPhotoDocuments) || booking.visible_passport === 'false') &&
                                ((booking.visible_document === 'true' && is_allAnversoDocument) || booking.visible_document === 'false')
                            )
                        )" class="ml-auto">
                        <i class="far fa-check-circle fa-lg icono-completo"></i>
                    </div>

                    <!-- <div class="ml-auto" v-else-if="booking.registered_guests == 0">
                    </div> -->
                    <div class="ml-auto" v-else>
                        <i class="far fa-exclamation-triangle fa-lg icono-completo"
                            style="color:var(--colores-amarillo-2);"></i>
                    </div>
                </router-link>


                <!-- Pagament -->
                <a v-if="isMarcaBlanca" :href="`https://${user.user_app}/payment?id=${paramID}`"
                    class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-wallet fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia" v-cloak>
                            {{ translator.gestiopagements }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak>
                            <span
                                v-if="booking.paymentCompleted && (bookingPayments.deposit_paid == 'true' || bookingPayments.security_deposit == '0.00')">{{
                                    translator.pagat }}</span>
                            <span
                                v-else-if="booking.paymentCompleted && (bookingPayments.deposit_paid == 'false' || bookingPayments.security_deposit != '0.00')">{{
                                    translator.diposit }}</span>
                            <span v-else>{{ booking.pending_payment_status }} {{ translator.perpagar | toLower }}</span>
                        </div>
                    </div>
                    <!-- || booking.mandatory_payment_at_check_in == 'false') -->
                    <div class="ml-auto"
                        v-if="booking.paymentCompleted && (bookingPayments.deposit_paid == 'true' || bookingPayments.security_deposit == '0.00' || booking.mandatory_deposit_guarantee_at_check_in == 'false')">
                        <i class="far fa-check-circle fa-lg icono-completo"></i>
                    </div>
                    <div class="ml-auto" v-else>
                        <i class="far fa-exclamation-triangle fa-lg icono-completo"
                            style="color:var(--colores-amarillo-2);"></i>
                    </div>
                    <!-- <div class="ml-auto" v-if="((booking.mandatory_payment_at_check_in == 'true' && parseInt(booking.pending_payment) != 0 && (bookingPayments.deposit_paid == 'true' || parseInt(bookingPayments.security_deposit) == 0 )) || booking.mandatory_payment_at_check_in == 'true') ">
                        <i class="far fa-exclamation-triangle fa-lg icono-completo" style="color:var(--colores-amarillo-2);"></i>
                    </div> -->
                </a>
                <router-link v-else :to="{ name: 'GuestPayment', query: { id: paramID } }"
                    class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-wallet fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia" v-cloak>
                            {{ translator.gestiopagements }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak>
                            <span
                                v-if="booking.paymentCompleted && (bookingPayments.deposit_paid == 'true' || bookingPayments.security_deposit == '0.00')">{{
                                    translator.pagat }}</span>
                            <span
                                v-else-if="booking.paymentCompleted && (bookingPayments.deposit_paid == 'false' || bookingPayments.security_deposit != '0.00')">{{
                                    translator.diposit }}</span>
                            <span v-else>{{ booking.pending_payment_status }} {{ translator.perpagar | toLower }}</span>
                        </div>
                    </div>
                    <!-- || booking.mandatory_payment_at_check_in == 'false') -->
                    <div class="ml-auto"
                        v-if="booking.paymentCompleted && (bookingPayments.deposit_paid == 'true' || bookingPayments.security_deposit == '0.00' || booking.mandatory_deposit_guarantee_at_check_in == 'false')">
                        <i class="far fa-check-circle fa-lg icono-completo"></i>
                    </div>
                    <div class="ml-auto" v-else>
                        <i class="far fa-exclamation-triangle fa-lg icono-completo"
                            style="color:var(--colores-amarillo-2);"></i>
                    </div>
                    <!-- <div class="ml-auto" v-if="((booking.mandatory_payment_at_check_in == 'true' && parseInt(booking.pending_payment) != 0 && (bookingPayments.deposit_paid == 'true' || parseInt(bookingPayments.security_deposit) == 0 )) || booking.mandatory_payment_at_check_in == 'true') ">
                        <i class="far fa-exclamation-triangle fa-lg icono-completo" style="color:var(--colores-amarillo-2);"></i>
                    </div> -->
                </router-link>


                <!-- Contracte -->
                <a v-if="isMarcaBlanca" :href="`https://${user.user_app}/contract?id=${paramID}`"
                    class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-signature fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia" v-cloak>
                            {{ translator.contracte }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak>
                            <span v-if="booking.contractCompleted">
                                {{ translator.signat }}
                            </span>
                            <span v-else>
                                {{ translator.nosignat }}
                            </span>
                        </div>
                    </div>

                    <div class="ml-auto" v-if="guestSignature != ''">
                        <i class="far fa-check-circle fa-lg icono-completo"></i>
                    </div>
                    <div class="ml-auto" v-else>
                        <i class="far fa-exclamation-triangle fa-lg icono-completo"
                            style="color:var(--colores-amarillo-2);"></i>
                    </div>
                </a>
                <router-link v-else :to="{ name: 'GuestContract', query: { id: paramID } }"
                    class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-signature fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia" v-cloak>
                            {{ translator.contracte }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak>
                            <span v-if="booking.contractCompleted">
                                {{ translator.signat }}
                            </span>
                            <span v-else>
                                {{ translator.nosignat }}
                            </span>
                        </div>
                    </div>

                    <div class="ml-auto" v-if="guestSignature != ''">
                        <i class="far fa-check-circle fa-lg icono-completo"></i>
                    </div>
                    <div class="ml-auto" v-else>
                        <i class="far fa-exclamation-triangle fa-lg icono-completo"
                            style="color:var(--colores-amarillo-2);"></i>
                    </div>
                </router-link>


                <!-- Panys portes -->
                <div v-if="booking.smart_door_lock == 'true' || booking.key_box_code != ''">
                    <a v-if="isMarcaBlanca" :href="`https://${user.user_app}/key?id=${paramID}`"
                        class="contenedor-reserva shadow-sm d-flex align-items-center">
                        <div class="icono">
                            <i class="fal fa-key fa-2x"></i>
                        </div>
                        <div class="info pl-3">
                            <div class="periodo-estancia" v-cloak>
                                {{ translator.clau }}
                            </div>
                            <div class="nombre-alojamiento" v-cloak>
                                {{ translator.missatge_clau }}
                                {{ booking.arrival_date | transformDate }}
                            </div>
                        </div>
                        <div class="ml-auto" v-if="booking.contractCompleted">
                            <i class="fal fa-check-circle fa-lg icono-completo"></i>
                        </div>
                    </a>
                    <router-link v-else :to="{ name: 'GuestKey', query: { id: $route.query.id } }"
                        class="contenedor-reserva shadow-sm d-flex align-items-center">
                        <div class="icono">
                            <i class="fal fa-key fa-2x"></i>
                        </div>
                        <div class="info pl-3">
                            <div class="periodo-estancia" v-cloak>
                                {{ translator.clau }}
                            </div>
                            <div class="nombre-alojamiento" v-cloak>
                                {{ translator.missatge_clau }}
                                {{ booking.arrival_date | transformDate }}
                            </div>
                        </div>
                        <div class="ml-auto" v-if="booking.contractCompleted">
                            <i class="fal fa-check-circle fa-lg icono-completo"></i>
                        </div>
                    </router-link>
                </div>


                <!-- Suplementos -->
                <div v-if="booking.supplements == 'true'">
                    <a v-if="isMarcaBlanca" :href="`https://${user.user_app}/supplements?id=${paramID}`"
                        class="contenedor-reserva shadow-sm d-flex align-items-center">
                        <div class="icono">
                            <i class="fal fa-bell-concierge fa-2x"></i>
                        </div>
                        <div class="info pl-3">
                            <div class="periodo-estancia" v-cloak>
                                {{ translator.suplements }}
                            </div>
                        </div>
                    </a>
                    <router-link v-else :to="{ name: 'GuestSupplements', query: { id: paramID } }"
                        class="contenedor-reserva shadow-sm d-flex align-items-center">
                        <div class="icono">
                            <i class="fal fa-bell-concierge fa-2x"></i>
                        </div>
                        <div class="info pl-3">
                            <div class="periodo-estancia" v-cloak>
                                {{ translator.suplements }}
                            </div>
                        </div>
                    </router-link>
                </div>


                <!-- Actividades -->
                <div v-if="booking.activities == 'true'">
                    <a v-if="isMarcaBlanca" :href="`https://${user.user_app}/activities?id=${paramID}`"
                        class="contenedor-reserva shadow-sm d-flex align-items-center">
                        <div class="icono">
                            <i class="fal fa-theater-masks fa-2x"></i>
                        </div>
                        <div class="info pl-3">
                            <div class="periodo-estancia" v-cloak>
                                {{ translator.activitats }}
                            </div>
                        </div>
                        <!-- <div class="ml-auto">
                        </div> -->
                    </a>
                    <router-link v-else :to="{ name: 'GuestActivities', query: { id: paramID } }"
                        class="contenedor-reserva shadow-sm d-flex align-items-center">
                        <div class="icono">
                            <i class="fal fa-theater-masks fa-2x"></i>
                        </div>
                        <div class="info pl-3">
                            <div class="periodo-estancia" v-cloak>
                                {{ translator.activitats }}
                            </div>
                        </div>
                        <!-- <div class="ml-auto">
                        </div> -->
                    </router-link>
                </div>


                <!-- Reviews -->
                <!-- <router-link :to="{ name: 'GuestReviews', query: { id: paramID }}" class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-comments fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia">
                            *Reviews
                            {{ translator.testimonis }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak></div>
                    </div>
                </router-link> -->


                <!-- Condiciones legales -->
                <a v-if="isMarcaBlanca" :href="`https://${user.user_app}/terms?id=${paramID}`"
                    class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-file-alt fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia">
                            {{ translator.condicions }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak>
                        </div>
                    </div>
                </a>
                <router-link v-else :to="{ name: 'GuestTerms', query: { id: paramID } }"
                    class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-file-alt fa-2x"></i>
                    </div>
                    <div class="info pl-3">
                        <div class="periodo-estancia">
                            {{ translator.condicions }}
                        </div>
                        <div class="nombre-alojamiento" v-cloak>
                        </div>
                    </div>
                </router-link>


                <!-- Mostrar Wifi -->
                <div v-if="booking.wifi_pwd != ''" class="contenedor-reserva shadow-sm d-flex align-items-center">
                    <div class="icono">
                        <i class="fal fa-wifi fa-2x"></i>
                    </div>
                    <div class="info pl-3 w-100">
                        <div class="periodo-estancia d-flex justify-content-between">
                            <div class="d-flex">
                                <div>
                                    {{ translator.wifinet }}: <span class="wifi">{{ booking.wifi_net }}</span>
                                </div>
                                <div class="ml-4">
                                    {{ translator.wifipwd }}: <span class="wifi">{{ booking.wifi_pwd }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-1">
                        <div @click="copiaWifi" class="p-2 map">
                            <div class="icono">
                                <i class="fal fa-copy fa-2x"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <h2 v-if="instruccions.length >= 1" class="pt-3">
                    {{ translator.informacioestada }}
                </h2>

                <!-- Instruccions -->
                <div v-for="info in instruccions" :key="info.info_id">

                    <a v-if="isMarcaBlanca"
                        :href="`https://${user.user_app}/instructions/${info.info_id}?id=${paramID}`"
                        class="contenedor-reserva shadow-sm d-flex align-items-center">
                        <div class="icono">
                            <i v-if="info.info_id == 'P1'" class="fal fa-sign-in fa-2x"></i>
                            <i v-if="info.info_id == 'P2'" class="fal fa-sign-out fa-2x"></i>
                            <i v-if="info.info_id == 'P3'" class="fal fa-file fa-2x"></i>
                            <i v-if="info.info_id != 'P3' && info.info_id != 'P2' && info.info_id != 'P1'"
                                class="fa-light fa-file-check fa-2x"></i>
                        </div>
                        <div class="info pl-3">

                            <div class="periodo-estancia" v-cloak>
                                {{ info.info_headline }}
                            </div>
                        </div>
                        <div class="ml-auto">
                            <i class="fal fa-chevron-right icono-instrucciones"></i>
                        </div>
                    </a>
                    <router-link v-else
                        :to="{ name: 'GuestInstructions', params: { instruction: info.info_id }, query: { id: paramID } }"
                        class="contenedor-reserva shadow-sm d-flex align-items-center">
                        <div class="icono">
                            <i v-if="info.info_id == 'P1'" class="fal fa-sign-in fa-2x"></i>
                            <i v-if="info.info_id == 'P2'" class="fal fa-sign-out fa-2x"></i>
                            <i v-if="info.info_id == 'P3'" class="fal fa-file fa-2x"></i>
                            <i v-if="info.info_id != 'P3' && info.info_id != 'P2' && info.info_id != 'P1'"
                                class="fa-light fa-file-check fa-2x"></i>
                        </div>
                        <div class="info pl-3">

                            <div class="periodo-estancia" v-cloak>
                                {{ info.info_headline }}
                            </div>
                        </div>
                        <div class="ml-auto">
                            <i class="fal fa-chevron-right icono-instrucciones"></i>
                        </div>
                    </router-link>

                </div>

                <!-- Links externos -->
                <div v-for="(link, i) in linksExternals" :key="i">

                    <a :href="link.link_url" target="_blank"
                        class="contenedor-reserva shadow-sm d-flex align-items-center">
                        <div class="icono">
                            <i class="fa-light fa-arrow-up-right-from-square"></i>
                        </div>
                        <div class="info pl-3">
                            <div class="periodo-estancia" v-cloak>
                                {{ link.link_headline }}
                            </div>
                        </div>
                        <div class="ml-auto">
                            <i class="fal fa-chevron-right icono-instrucciones"></i>
                        </div>
                    </a>

                </div>

            </div>

            <!-- Buto del xat -->
            <div class="container-chat">
                <router-link :to="{ name: 'GuestChat', query: { id: paramID } }">
                    <i class="fad fa-comments-alt fa-2x shadow-sm"
                        style="background: #fff; padding: 15px; border-radius: 50px;"></i>
                    <div v-if="isNewMessage" class="icono-exclamacion" v-cloak>
                        <i class="fas fa-exclamation"></i>
                    </div>
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
import NavbarGuest from "@/components/NavbarGuest.vue";
import { mapState } from "vuex";

export default {
    name: "Guest",
    components: {
        NavbarGuest,
    },
    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("guest", ['booking', 'bookingGuests', 'bookingPayments', 'bookingContract', 'instruccions', 'linksExternals', 'isNewMessage', 'smartKeys', 'stateDoor']),
        huespedesCompleto() {
            if ((
                (this.booking.mandatory_guests_at_check_in === 'true' && this.booking._registered_adults_only_completed) ||
                this.booking.mandatory_guests_at_check_in === 'false'
            ) &&
                (
                    (this.booking.mandatory_signatures_contract === 'true' && this.controlAdultWithSign) ||
                    this.booking.mandatory_signatures_contract === 'false'
                ) &&
                (
                    (this.booking.mandatory_guests_contract === 'true' && this.isFilledFields && this.booking._registered_adults_only_completed) ||
                    this.booking.mandatory_guests_contract === 'false'
                ) &&
                (
                    this.booking.mandatory_guests_at_check_in == 'false' ||
                    (
                        (this.booking.mandatory_guests_at_check_in === 'true') &&
                        ((this.booking.visible_selfie === 'true' && this.is_allSelfiGuests) || this.booking.visible_selfie === 'false') &&
                        ((this.booking.visible_passport === 'true' && this.is_allPhotoDocuments) || this.booking.visible_passport === 'false') &&
                        ((this.booking.visible_document === 'true' && this.is_allAnversoDocument) || this.booking.visible_document === 'false')
                    )
                )) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        translator() {
            this.title = String(this.user.user_app.split('.')[1]).toUpperCase() == "ICNEA" ? "Guest app" : String(this.user.user_app.split('.')[1]).toUpperCase();
            this.dominio = this.user.user_app;

            this.$store.dispatch("guest/getBookingID", { usr: this.paramID });

            this.$store.dispatch("guest/getInstructions", { usr: this.paramID });
            this.$store.dispatch("guest/getLinksExternals", { usr: this.paramID });
            this.$store.dispatch("guest/getChatNewMessage", { usr: this.paramID });
        },
        booking() {
            this.ogImg = this.translator.logo;
            this.isPreLoad = false;
            this.$store.dispatch("guest/getBookingPayments", { usr: this.paramID, reserva: this.booking.reservation });
            this.$store.dispatch("guest/getBookingContract", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.booking.reservation });
            if (this.booking.mandatory_signatures_contract) {
                this.$store.dispatch("guest/getHostes", { usr: this.paramID, reserva: this.booking.reservation });
            }
            this.checkAdultsAge();
        },
        bookingGuests() {
            let adults = 0;
            let firms = 0;
            this.bookingGuests.forEach(guest => {
                guest._isAdult ? adults++ : '';
                guest._isAdult && guest._isSign ? firms++ : '';
                guest._input_empty ? this.isFilledFields = false : null;
                !guest.document_image ? this.is_allPhotoDocuments = false : null;
                !guest.guest_photo && guest._isAdult ? this.is_allSelfiGuests = false : null;
            });
            firms >= adults ? this.controlAdultWithSign = true : '';
            //firms >= adults && this.booking.adults <= adults ? this.controlAdultWithSign = true : '';
        },
        bookingContract() {
            this.guestSignature = this.bookingContract.guest.guest_signature;
        },
        stateDoor() {
            if (this.stateDoor === true) {
                this.$toast.success('Opening');
                this.$store.dispatch("guest/resetOpenDoor", { state: false });
            }
        }
    },
    async created() {
        this.user.user_app == 'app.icnea.net' ? null : this.isMarcaBlanca = true;
        let param = ''
        this.$route.query.id != undefined ? param = String(this.$route.query.id).replace(/[ ]/g, '+') : param = String(this.$route.params.id).replace(/[ ]/g, '+');
        this.paramID = param
        try {
            this.isPreLoad = true;
            await this.$store.dispatch("utils/getUser", { usr: this.paramID });
        } catch (error) {
            console.error(error);
        }
    },
    data() {
        return {
            isPreLoad: false,
            isMarcaBlanca: false,
            paramID: null,
            controlAdultWithSign: false,
            is_allSelfiGuests: true,
            is_allPhotoDocuments: true,
            is_allAnversoDocument: true,
            isFilledFields: true,
            guestSignature: '',
            dominio: '',
            title: '',
            ogUrl: '',
            ogImg: '',
        };
    },
    methods: {
        copiaWifi() {
            navigator.clipboard.writeText(this.booking.wifi_pwd);
            this.$toast.success(`${this.translator.copypwd}`);
        },
        openDoor(x) {
            this.$store.dispatch("guest/openDoor", {
                usr: this.routeUrl,
                reserva: this.booking.reservation,
                pany: x,
                ip: ''
            });
        },
        checkAdultsAge() {
            let registerAdults = 0,
                registerChildren = 0,
                signAdults = 0,
                signChildren = 0,
                totalSigns = 0,
                is_photosDocuments = true,
                is_uploadAllPhotosAndSelfies = true;

            this.bookingGuests.forEach(guest => {
                this.booking.mandatory_signatures_contract == 'true' && guest._isSign ? totalSigns++ : null;
                guest._isAdult ? registerAdults++ : registerChildren++;
                guest._isSign && guest._isAdult ? signAdults++ : signChildren++;
                if (guest._isAdult) {
                    guest.is_uploadAllPhotosAndSelfies ? null : is_uploadAllPhotosAndSelfies = false;
                    guest.document_image ? null : is_photosDocuments = false;
                    guest.document_photo ? null : this.is_allAnversoDocument = false;
                }

            });
            this.is_uploadAllPhotosAndSelfies = is_uploadAllPhotosAndSelfies
            this.is_photosDocuments = is_photosDocuments
            // Adultos
            parseInt(this.booking.adults) === registerAdults ? this.isAllRegisterAdults = true : '';
            parseInt(this.booking.adults) === signAdults || totalSigns >= parseInt(this.booking.adults) ? this.isAllSignAdults = true : ''; //modo restrictivo quitar || totalSigns >=...
            // Niños
            parseInt(this.booking.children) === registerChildren ? this.isAllRegisterChildren = true : '';
            parseInt(this.booking.children) === signChildren ? this.isAllSignChildren = true : '';

            console.log('total firmas >= al núm. adultos ?', this.isAllSignAdults)
        },
    },
    filters: {
        toLower(value) {
            if (!value) return '';
            value = value.toString();
            return value.toLowerCase();
        },
        transformDate(value) {
            if (!value) return '';
            const backendDate = value.split('-');
            return `${backendDate[2]}/${backendDate[1]}/${backendDate[0]}`;
        },
    },
    metaInfo() {
        return {
            title: this.title,
            meta: [
                { property: 'og:title', content: this.title },
                { property: 'og:image', content: this.ogImg },
                { property: 'og:type', content: 'website' },
            ]
        }
    }
};
</script>

<style scoped>
h2 {
    font-weight: 400;
}

a {
    color: #212529;
    text-decoration: none;
}

.key-code {
    font-size: 2rem;
}

.wifi {
    font-weight: normal;
}

.btn-apertura {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.btn-apertura button {
    max-width: 234px;
    min-width: 200px;
}

.map i:hover {
    color: red;

}
</style>