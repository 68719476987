<template>
    <div class="pc reserva booking-details">

        <NavbarGuest :translator="translator" :user="user" :booking="booking" />


        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!-- Fin spiner -->

        <div v-if="formBookingDetails.reservation" class="container mt-4">
            <h1 v-cloak>
                <i class="fal fa-file-check pr-1"></i>
                {{ translator.dadespersonals }}
            </h1>

            <label for="name" class="pt-3 pb-1" v-cloak>{{ translator.nom }}</label>
            <input id="name" v-model="formBookingDetails.name" type="text" class="form-control"
                autocomplete="noComplete" disabled="disabled" required />

            <label for="surname" class="pt-3 pb-1" v-cloak>
                {{ translator.cognoms }}
            </label>
            <input id="surname" v-model="formBookingDetails.surname" type="text" class="form-control"
                autocomplete="noComplete" disabled="disabled" required />

            <label for="email" class="pt-3 pb-1" v-cloak>
                {{ translator.email }}
            </label>
            <input id="email" v-model="formBookingDetails.email" type="email" class="form-control"
                autocomplete="noComplete" :disabled="formBookingDetails.isDisabled && booking.customer_email != ''"
                required />

            <label for="phone" class="pt-3 pb-1" v-cloak>
                {{ translator.telefon }}
            </label>
            <input id="phone" v-model="formBookingDetails.phone" type="tel" class="form-control col-6"
                autocomplete="noComplete" :disabled="formBookingDetails.isDisabled && booking.customer_phone != ''"
                required />

            <label for="address" class="pt-3 pb-1" v-cloak>
                {{ translator.adreca }}
            </label>

            <input id="address" v-model="formBookingDetails.adress" type="text" class="form-control"
                autocomplete="noComplete" :disabled="formBookingDetails.isDisabled && booking.customer_address != ''"
                required />

            <label for="city" class="pt-3 pb-1" v-cloak>
                {{ translator.poble }}
            </label>
            <input id="city" v-model="formBookingDetails.city" type="text" class="form-control"
                autocomplete="noComplete" :disabled="formBookingDetails.isDisabled && booking.customer_city != ''"
                required />

            <div class="row">
                <div class="col-4">
                    <label for="zip" class="pt-3 pb-1" v-cloak>
                        {{ translator.dip }}
                    </label>
                    <input id="zip" v-model="formBookingDetails.zip" type="text" class="form-control"
                        autocomplete="noComplete"
                        :disabled="formBookingDetails.isDisabled && booking.customer_zip != ''" required />
                </div>
                <div class="col-8">
                    <label for="country" class="pt-3 pb-1" v-cloak>
                        {{ translator.pais }}
                    </label>
                    <select id="country" v-model="formBookingDetails.country" class="form-control"
                        :disabled="formBookingDetails.isDisabled && booking.customer_country != ''" v-cloak>
                        <option v-for="(country, index) in countries" :value="country.alpha2Code" :key="index" v-cloak>
                            {{ country.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row align-items-end">
                <div class="col-12 col-md-4">
                    <label for="numDocument" class="pt-3 pb-1" v-cloak>
                        {{ translator.dnipassaport }}
                    </label>
                    <input id="numDocument" v-model="formBookingDetails.numDocument" type="text" class="form-control"
                        autocomplete="noComplete"
                        :disabled="formBookingDetails.isDisabled && booking.customer_tin != ''" required />
                </div>
                <div class="col-12 col-md-8">
                    <label for="countryNacionality" class="pt-3 pb-1" v-cloak>
                        {{ translator.nacionalitat }}
                    </label>
                    <select id="countryNacionality" v-model="formBookingDetails.countryNacionality" class="form-control"
                        :disabled="formBookingDetails.isDisabled && booking.customer_nationality != ''" v-cloak>
                        <option v-for="(country, index) in countries" :value="country.alpha2Code" :key="index" v-cloak>
                            {{ country.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label for="hourArrival" class="pt-3 pb-1" v-cloak>
                        {{ translator.horaentrada }}
                    </label>
                    <vue-timepicker v-model="formBookingDetails.hourArrival" hide-disabled-items placeholder=" "
                        :hour-range="[[booking._lodging_checkin_from_show_input, booking._lodging_checkin_to_show_input - 1]]"
                        :minute-interval="5" :disabled="esMenorA24HorasEntrada(booking.arrival_date)"></vue-timepicker>
                </div>
                <div class="col-6">
                    <label for="hourDeparture" class="pt-3 pb-1" v-cloak>
                        {{ translator.horasortida }}
                    </label>
                    <vue-timepicker v-model="formBookingDetails.hourDeparture" hide-disabled-items placeholder=" "
                        :hour-range="[[booking._lodging_checkout_from_show_input, booking._lodging_checkout_to_show_input - 1]]"
                        :minute-interval="5" :disabled="esMenorA24HorasSalida(booking.departure_date)"></vue-timepicker>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label for="flight" class="pt-3 pb-1" v-cloak>
                        {{ translator.nvol }}
                    </label>
                    <input id="flight" v-model="formBookingDetails.flight" type="text" class="form-control"
                        autocomplete="noComplete" :disabled="formBookingDetails.isDisabled" />
                </div>
            </div>

            <label for="remarks" class="pt-3 pb-1" v-cloak>
                {{ translator.observacions }}
            </label>
            <textarea rows="3" id="remarks" v-model="formBookingDetails.remarks" type="text" class="form-control"
                autocomplete="noComplete" :disabled="formBookingDetails.isDisabled"></textarea>

            <div v-if="errorFormBooking.length > 0" class="alert alert-danger mt-4" role="alert">
                <p v-for="(error, index) in errorFormBooking" :key="index">
                    - {{ error }}
                </p>
            </div>

            <!-- || !datosReservaCompletada -->
            <button class="btn btn-secondary mt-3" @click.prevent.self="validateFormBooking()" v-cloak>
                {{ translator.guardar }}
            </button>
        </div>

        <!-- Navegaciones footer -->
        <div class="cotainer d-flex justify-content-between">
            <div>
                <a v-if="isMarcaBlancaActive"
                    :href="`https://${user.user_app}/guest.html?id=${String(this.$route.query.id).replace(/[ ]/g, '+')}`"
                    class="nav-link todo-ok"><i class="fa-light fa-chevron-left"></i> {{ translator.inici }}</a>
                <router-link v-else class="nav-link todo-ok"
                    :to="{ name: 'Guest', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }">
                    <i class="fa-light fa-chevron-left"></i> {{ translator.inici }}
                </router-link>
            </div>

            <div>
                <a v-if="isMarcaBlancaActive && datosReservaCompletada"
                    :href="`https://app.icnea.net/guests?id=${paramID}`" class="nav-link">
                    {{ translator.hostes }} <i class="fa-light fa-chevron-right"></i>
                </a>

                <router-link v-else-if="datosReservaCompletada"
                    :to="{ name: 'GuestGuests', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } }"
                    class="nav-link todo-ok">
                    <span class="display-responsive">{{ translator.hostes }} <i
                            class="fa-light fa-chevron-right"></i></span>
                </router-link>

                <a v-else href="#" class="nav-link disabled">
                    {{ translator.hostes }} <i class="fa-light fa-chevron-right"></i>
                </a>
            </div>

        </div>
    </div>
</template>

<script>
import countries from '../../assets/js/countries.js'
import NavbarGuest from "@/components/NavbarGuest.vue";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { mapState } from "vuex";

export default {
    name: "GuestDetails",
    components: {
        NavbarGuest,
        VueTimepicker
    },
    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("guest", ["booking"]),
        datosReservaCompletada() {
            if (this.booking.customer_address &&
                this.booking.customer_city &&
                this.booking.customer_country &&
                this.booking.customer_email &&
                this.booking.customer_first_name &&
                this.booking.customer_last_name &&
                this.booking.customer_phone &&
                this.booking.customer_tin &&
                this.booking.customer_zip &&
                this.booking.customer_nationality
            ) {
                return true
            } else {
                return false
            }
        }
    },

    watch: {
        translator() {
            this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking })
        },
        booking() {
            this.assignFormData();
            this.isPreLoad = false;
            this.verifyCheckin();
        },
    },

    data() {
        return {
            isPreLoad: false,
            countries: countries,
            formBookingDetails: {
                reservation: "",
                name: "",
                surname: "",
                adress: "",
                city: "",
                zip: "",
                country: "",
                countryNacionality: "",
                numDocument: "",
                phone: "",
                email: "",
                adults: "",
                children: "",
                babies: "",
                hourArrival: "",
                hourDeparture: "",
                flight: "",
                remarks: "",
                isDisabled: "",
                isHoursCheckinDisabled: false,
                intervalHoursCheckin: [],
                isMarcaBlancaActive: false

            },
            errorFormBooking: []
        };
    },

    async created() {
        this.isPreLoad = true;
        (window.location.host == 'app.icnea.net' || window.location.host == 'xapp.icnea.net' || window.location.host == 'localhost:8080') ? this.isMarcaBlancaActive = false : this.isMarcaBlancaActive = true;
        try {
            this.translator
                ? await this.$store.dispatch("guest/getBooking", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking })
                : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });

        } catch (error) {
            console.error(error);
        }
    },

    methods: {
        assignFormData() {
            this.formBookingDetails.reservation = this.booking.reservation;
            this.formBookingDetails.name = this.booking.customer_first_name;
            this.formBookingDetails.surname = this.booking.customer_last_name;
            this.formBookingDetails.adress = this.booking.customer_address;
            this.formBookingDetails.city = this.booking.customer_city;
            this.formBookingDetails.country = this.booking.customer_country;
            this.formBookingDetails.countryNacionality = this.booking.customer_nationality;
            this.formBookingDetails.zip = this.booking.customer_zip;
            this.formBookingDetails.numDocument = this.booking.customer_tin;
            this.formBookingDetails.phone = this.booking.customer_phone;
            this.formBookingDetails.adults = this.booking.adults;
            this.formBookingDetails.children = this.booking.children;
            this.formBookingDetails.babies = this.booking.babies;
            this.formBookingDetails.flight = this.booking.customer_flight;
            this.formBookingDetails.remarks = this.booking.customer_remarks;
            const email = this.booking.customer_email
            if (email.endsWith('airbnb.com') || email.endsWith('booking.com')) {
                this.formBookingDetails.email = '';
            } else {
                this.formBookingDetails.email = this.booking.customer_email;
            }

            //Si no es múltiple de 5, vaciamos el campo y el cliente debera poner la hora.
            this.booking._arrival_time[String(this.booking.arrival_time).length - 1] % 5 == 0
                ? this.formBookingDetails.hourArrival = this.booking._arrival_time
                : this.formBookingDetails.hourArrival = '';

            this.booking.departure_time[String(this.booking.departure_time).length - 1] % 5 == 0
                ? this.formBookingDetails.hourDeparture = this.booking._departure_time
                : this.formBookingDetails.hourDeparture = '';


            this.formBookingDetails.isDisabled = this.verifyCheckin();
        },

        validateFormBooking() {
            this.errorFormBooking = [];
            const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
            //Validar nombre minimo de 2 letras
            const regexNames = /^[a-zA-ZáéíóúÁÉÍÓÚñÑàèìòùÀÈÌÒÙäëïöüÄËÏÖÜ\s]{2,}/;

            if (this.formBookingDetails.name === '' || !regexNames.test(this.formBookingDetails.name)) {
                this.errorFormBooking.push(this.translator.faltanom)
            }
            if (this.formBookingDetails.surname === '' || !regexNames.test(this.formBookingDetails.surname)) {
                this.errorFormBooking.push(this.translator.faltacognom)
            }
            this.formBookingDetails.adress === '' ? this.errorFormBooking.push(this.translator.faltaadreca) : null;
            this.formBookingDetails.country === '' ? this.errorFormBooking.push(this.translator.pais) : null;
            this.formBookingDetails.countryNacionality === '' ? this.errorFormBooking.push(this.translator.nacionalitat) : null;
            this.formBookingDetails.city === '' ? this.errorFormBooking.push(this.translator.faltapoble) : null;
            this.formBookingDetails.zip === '' ? this.errorFormBooking.push(this.translator.faltadip) : null;
            this.formBookingDetails.numDocument === '' ? this.errorFormBooking.push(this.translator.faltadnipassaport) : null;
            this.formBookingDetails.phone === '' ? this.errorFormBooking.push(this.translator.faltatelefon) : null;
            regEmail.test(this.formBookingDetails.email) ? null : this.errorFormBooking.push(this.translator.faltaemail);

            if (!this.esMenorA24HorasEntrada(this.booking.arrival_date)) {
                this.formBookingDetails.hourArrival === '' ? this.errorFormBooking.push(this.translator.horaentrada) : null
            }
            if (!this.esMenorA24HorasSalida(this.booking.departure_date)) {
                this.formBookingDetails.hourDeparture === '' ? this.errorFormBooking.push(this.translator.horasortida) : null
            }

            this.errorFormBooking.length === 0 ? this.bookingSave() : ''
        },

        async bookingSave() {
            this.isPreLoad = true;

            try {
                this.isPreLoad = true;
                await this.$store.dispatch("guest/postBookingSave", {
                    usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                    reservation: this.formBookingDetails.reservation,
                    language: this.booking.customer_language,
                    nom: this.formBookingDetails.name,
                    cognom: this.formBookingDetails.surname,
                    adreca: this.formBookingDetails.adress,
                    poble: this.formBookingDetails.city,
                    dip: this.formBookingDetails.zip,
                    pais: this.formBookingDetails.country,
                    telefon: this.formBookingDetails.phone,
                    email: this.formBookingDetails.email,
                    cif: this.formBookingDetails.numDocument,
                    nacionalitat: this.formBookingDetails.countryNacionality,
                    adults: this.formBookingDetails.adults,
                    joves: this.formBookingDetails.children,
                    bebes: this.formBookingDetails.babies,
                    observacions: this.formBookingDetails.remarks,
                    numvol: this.formBookingDetails.flight,
                    horaentrada: this.formBookingDetails.hourArrival,
                    horasortida: this.formBookingDetails.hourDeparture
                });

                // Muestro notificación
                this.$toast.success(this.translator.guardat);
                // setTimeout(()=> {
                //     // Redirigir inicial
                //     //this.$router.push({ name: 'Guest', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } })
                //     this.$router.push({ name: 'GuestGuests', query: { id: String(this.$route.query.id).replace(/[ ]/g, '+') } })
                // }, 600)

            } catch (error) {
                console.error(error);
            } finally {
                this.isPreLoad = false;
            }
        },

        verifyCheckin() {
            const formatYmd = (date) => date.toISOString().slice(0, 10);
            const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
            const compare = (a, b) => a.getTime() < b.getTime();

            // Si se ha hecho el checkin o se ha asignado una hora de entrada, bloqueamos la hora de entrada el dia de entrada al alojamiento.
            if (this.booking.checkinCompleted || this.booking._arrival_time[String(this.booking.arrival_time).length - 1] % 5 == 0) {
                diffDays(new Date(formatYmd(new Date(this.booking.arrival_date))), new Date(formatYmd(new Date()))) == 0 || parseInt(this.booking.status) >= 6 || compare(new Date(formatYmd(new Date(this.booking.arrival_date))), new Date(formatYmd(new Date())))
                    ? this.formBookingDetails.isHoursCheckinDisabled = "disabled"
                    : null;
            }

            parseInt(this.booking.status) >= 6 || compare(new Date(formatYmd(new Date(this.booking.arrival_date))), new Date(formatYmd(new Date())))
                ? this.formBookingDetails.isDisabled = "disabled"
                : null;
        },

        esMenorA24HorasEntrada(arrival) {
            const arrivalDateTime = new Date(arrival);
            arrivalDateTime.setHours(12, 0, 0, 0);

            const now = new Date();
            const diffInMs = arrivalDateTime - now;
            const diffInHours = diffInMs / (1000 * 60 * 60);

            // Retorna true si la diferencia es menor a 24 horas
            return diffInHours <= 24;
        },
        esMenorA24HorasSalida(departureDate) {
            const departureDateTime = new Date(departureDate);
            departureDateTime.setHours(12, 0, 0, 0);

            const now = new Date();
            const diffInMs = departureDateTime - now;
            const diffInHours = diffInMs / (1000 * 60 * 60);

            // Retorna true si la diferencia es menor a 24 horas
            return diffInHours <= 24;
        }

    }
}
</script>

<style lang="scss" scoped>
a {
    color: #0c2c40;
    text-decoration: none;
}

.todo-ok {
    color: var(--colores-verde-check) !important;
}

.falta-info {
    color: var(--colores-amarillo-2) !important;
}
</style>